<template>
<!--  <validation-observer ref="login_observer" v-slot="{ invalid }">-->
<!--    <form @submit.prevent="submit">-->
<!--      <validation-provider v-slot="{ errors }" name="Email" rules="required">-->
<!--        <v-text-field-->
<!--          filled-->
<!--          clearable-->
<!--          v-model="loginFormData.email"-->
<!--          :error-messages="errors"-->
<!--          :label="$t('auth.login.username')"-->
<!--          required-->
<!--        ></v-text-field>-->
<!--      </validation-provider>-->

<!--      <validation-provider-->
<!--        v-slot="{ errors }"-->
<!--        name="Password"-->
<!--        :rules="{-->
<!--          required: true,-->
<!--        }"-->
<!--      >-->
<!--        <v-text-field-->
<!--          filled-->
<!--          clearable-->
<!--          :type="showPass ? 'text' : 'password'"-->
<!--          @click:append="showPass = !showPass"-->
<!--          :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"-->
<!--          v-model="loginFormData.password"-->
<!--          :error-messages="errors"-->
<!--          label="Password"-->
<!--        ></v-text-field>-->
<!--      </validation-provider>-->

<!--      &lt;!&ndash; Remember &ndash;&gt;-->
<!--      <validation-provider name="Remember">-->
<!--        <v-checkbox-->
<!--          v-model="loginFormData.remember"-->
<!--          :value="false"-->
<!--          label="Remember me"-->
<!--        ></v-checkbox>-->
<!--      </validation-provider>-->

<!--      <v-container fluid class="text-center">-->
<!--        <v-btn-->
<!--          text-->
<!--          type="submit"-->
<!--          :disabled="invalid || loadings.loginBtn"-->
<!--          color="primary"-->
<!--          :loading="loadings.loginBtn"-->
<!--        >-->
<!--          {{ $t("generals.continue") }}-->
<!--        </v-btn>-->
<!--      </v-container>-->
<!--    </form>-->
<!--  </validation-observer>-->
  <div></div>
</template>
<script>
import { required, min, regex } from "vee-validate/dist/rules";
import {
  extend,
  // ValidationObserver,
  // ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import { mapActions, mapGetters } from "vuex";
const randomstring = require("randomstring");
const pkceChallenge = require('pkce-challenge');


setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

extend("regex", {
  ...regex,
  message:
    "{_field_} must contains at least: 1 Number, 1 Uppercase letter and 1 Lowercase letter",
});

extend("min", {
  ...min,
  message: "{_field_} must be at least than 8 characters",
});

export default {
  components: {
    // ValidationProvider,
    // ValidationObserver,
  },
  data: () => ({
    loadings: {
      loginBtn: false,
    },
    loginFormData: {
      email: "",
      password: "",
      remember: false,
    },
    showPass: false,
  }),
  computed: {
    ...mapGetters(["isLoggedIn"]),
  },
  mounted() {

    const code = this.$route.query.code;

    if (!code) {
      window.location.href = this.mountOauthUrl();
    } else {
      const state = localStorage.get('state');
      const codeVerifier = localStorage.get('codeVerifier');
      const urlState = this.$route.query.state;
      const self = this;

      if(state.length <= 0 || state !== urlState){
        self.$swal.fire({
          timer: 2000,
          icon: "error",
          title: self.$t("dialogs.titles.error"),
          text: self.$t("auth.errors.oauthError"),
        });

        return;
      }

      const data = {
        codeVerifier: codeVerifier,
        code: code
      }


      self.initSession(data)
        .then((suss) =>{
          if(!suss.access_token && !self.isLoggedIn) return;
          self.$router.push({name: "Home"}).finally(() =>{
            self.clearForm();
          });
        })
        .catch((err) => {
          if (err.status == 401) {
            self.$swal.fire({
              timer: 2000,
              icon: "error",
              title: self.$t("dialogs.titles.error"),
              text: self.$t("auth.errors.codeOauthError"),
            });
          } else if (err.status == 500) {
            self.$swal.fire({
              timer: 2000,
              icon: "error",
              title: self.$t("dialogs.titles.error"),
              text: self.$t("dialogs.body.error"),
            });
          }
        })
    }
  },
  methods: {
    ...mapActions(["initSession"]),
    async submit() {
      const self = this;
      const valid = await self.$refs.login_observer.validate();
      if (!valid) return;
      self.loadings.loginBtn = true;
      self
        .initSession(self.loginFormData)
        .then((suss) => {
          if (!suss.access_token && !self.isLoggedIn) return;
          self.$router.push({ name: "Home" }).finally(() => {
            self.clearForm();
          });
        })
        .catch((err) => {
          if (err.status == 401) {
            self.$swal.fire({
              timer: 2000,
              icon: "error",
              title: self.$t("dialogs.titles.error"),
              text: self.$t("auth.errors.badCredentials"),
            });
          } else if (err.status == 500) {
            self.$swal.fire({
              timer: 2000,
              icon: "error",
              title: self.$t("dialogs.titles.error"),
              text: self.$t("dialogs.body.error"),
            });
          }
        })
        .finally(() => {
          self.loadings.loginBtn = false;
        });
    },
    clearForm() {
      const self = this;
      self.loginFormData = {};
    },
    trimLastIfEquals(str,char){
      if(str[str.length - 1] == char){
        str = str.substring(0, str.length - 1);
      }

      return str;
    },
    mountOauthUrl(){

      const serverUrl = process.env.VUE_APP_OAUTH_SERVER_URL;
      const clientId = process.env.VUE_APP_CLIENT_ID;
      const scope = '*';
      const redirectUri = process.env.VUE_APP_REDIRECT_URI + '/auth/callback';
      const responseType = 'code';

      const state = randomstring.generate(40);
      localStorage.setItem('state', state);

      const {code_verifier, code_challenge} = pkceChallenge(128);
      localStorage.setItem('codeVerifier', code_verifier);

      return `${serverUrl}?client_id=${clientId}&scope=${scope}&redirect_uri=${redirectUri}&response_type=${responseType}&state=${state}&code_challenge=${code_challenge}&code_challenge_method=S256`;
    },
    generateString(length){
      let result = '';
      const characters= 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
      const charactersLength = characters.length;

      for(let i = 0; i < length; i++){
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }

      return result;
    }
  },
};
</script>
